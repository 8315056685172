import { Injectable } from '@angular/core';

export enum FormActions {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete'
}

export enum InspectionStatus{
  UNKNOWN = 'Unknown',
  PENDING = 'Pending',
}

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  convertJSONToFormData(data){
    const formData = new FormData();
    this.buildFormData(formData, data,null);
    return formData;
  };

  buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
      const value = data == null ? '' : data;
      formData.append(parentKey, value);
    }
  }

  public static convertModelToFormData(model: any, form: FormData = null, namespace = ''): FormData {
    let formData = form || new FormData();
    let formKey;

    for (let propertyName in model) {
      if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
      let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      if (model[propertyName] instanceof Date){
        formData.append(formKey, model[propertyName].toISOString());
      }
      else if (model[propertyName] instanceof Array) {
        formData.append(propertyName, JSON.stringify(model[propertyName]));
      }
      else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)){
        this.convertModelToFormData(model[propertyName], formData, formKey);
      }
      else{
        console.log(formKey,model[propertyName]);
        formData.append(formKey, model[propertyName].toString());
      }
    }
    return formData;
  }
}
